<template>
  <!-- 
    画面: エリア新規登録
    用途: エリアを新規登録する
    TODO:APIや画面両方に今後修正が入ってくる
   -->
  <div>
   <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <strong class="card-title">{{ $t("areaCreatePage.pageTitle") }}</strong>
          </CCardHeader>
          <CCardBody class="px-5">
            <div class="area-edit">
              <CRow>
                <CCol>
                  <h6 class="mb-3"><strong>日本語</strong></h6>
                  <strong class="form_label">{{
                    $t("areaCreatePage.id")
                  }}</strong>
                  <CInput
                    type="number"
                    v-model="currentID"
                  />
                  <strong class="form_label">{{
                    $t("areaCreatePage.name")
                  }}</strong>
                  <CInput v-model="AddAreaJa.name" />
                  <strong class="form_label">{{
                    $t("areaCreatePage.pronunciation")
                  }}</strong>
                  <CInput v-model="AddAreaJa.pronunciation" />
                  <strong class="form_label">{{
                    $t("areaCreatePage.description")
                  }}</strong>
                  <CTextarea v-model="AddAreaJa.description" />
                </CCol>
                <CCol>
                  <CTabs>
                    <CTab :active="true">
                      <template slot="title"> English </template>
                      <strong class="form_label">ID</strong>
                      <CInput
                        v-model="currentID"
                        disabled
                      />
                      <strong class="form_label">Name</strong>
                      <CInput v-model="AddAreaEn.name" />
                      <strong class="form_label">Japanese Name</strong>
                      <CInput v-model="AddAreaEn.pronunciation" />
                      <strong class="form_label">Info</strong>
                      <CTextarea v-model="AddAreaEn.description" />
                    </CTab>
                    <CTab>
                      <template slot="title"> 中文 </template>
                      <strong class="form_label">ID</strong>
                      <CInput
                        v-model="AddAreaZh.id"
                        disabled
                      />
                      <strong class="form_label">Name (Chinese)</strong>
                      <CInput v-model="AddAreaZh.name" />
                      <strong class="form_label">Pronunciation(Chinese)</strong>
                      <CInput v-model="AddAreaZh.pronunciation" />
                      <strong class="form_label">Info (Chinese)</strong>
                      <CTextarea v-model="AddAreaZh.description" />
                    </CTab>
                  </CTabs>
                </CCol>
              </CRow>
            </div>
            </CCardBody>
            <CCardFooter>
               <!-- #region 戻るボタン -->
                <CButton
                  color="secondary"
                  @click="onBackClicked()"
                ><i class="icon cil-arrow-left mr-2"></i>{{ $t("common.back") }}</CButton>
                <!-- #endregion 戻るボタン -->
              <CButton
                @click="AddAreasData()"
                color="success"
              class="float-right"
            >
              <i class="icon cil-save mr-1"></i> {{
              $t("common.register") }}
            </CButton>
            </CCardFooter>
          </CCard>
        </CCol>
      </CRow>

      <CModal
        color="success"
        :closeOnBackdrop="false"
        :centered="true"
        :show.sync="successModal"
      >
        {{ $t("commonMessage.updateSuccessText") }}
        <template #header> {{ $t("commonMessage.updateSuccessHeader") }}
        </template>
        <template #footer>
          <CButton
            @click="successModal = false"
            color="secondary"
          >
            <i class="icon cil-x"></i> {{$t('common.close')}}
          </CButton>
        </template>
      </CModal>
      <CModal
        color="danger"
        closeOnBackdrop
        :centered="true"
        :show.sync="errorModal"
      >
        <p>{{ errorMessage }}</p>
        <template #header>
          <h4 class="modal-title">{{$t('common.error')}}</h4>
        </template>
        <template #footer>
          <CButton
            @click="errorModal = false"
            color="secondary"
          >
            <i class="icon cil-x"></i> {{$t('common.close')}}

          </CButton>
        </template>
      </CModal>

  </div>
</template>
<script>
import i18n from "../../../i18n";
import axios from "axios";

export default {
  name: "AreaList",
  data() {
    return {
      loading: false,
      successModal: false,
      errorModal: false,
      AddAreaJa: "",
      AddAreaEn: "",
      AddAreaZh: "",
      currentID: "",
      errorMessage: "",
      currentArea: [],
      newArea: [],
      TableFields: [
        {
          key: "id",
          label: `${i18n.t("areaCreatePage.id")}`,
          _classes: "text-center",
          _style: "width:7%",
        },
        {
          key: "name",
          label: `${i18n.t("areaCreatePage.name")}`,
          _classes: "text-center",
          _style: "width:14%",
        },
        {
          key: "pronunciation",
          label: `${i18n.t("areaCreatePage.pronunciation")}`,
          _classes: "text-center",
        },
        {
          key: "description",
          label: `${i18n.t("areaCreatePage.description")}`,
          _classes: "text-center",
        },
        {
          key: "operation",
          label: "",
          sorter: false,
          filter: false,
          _style: "width:5%",
          _classes: "text-center",
        },
      ],
    };
  },

  methods: {
    /** submit area data */
    AddAreasData() {
      this.currentArea.map((el) => {
        el.id = this.currentID;
      });
      const urlRequest = "/rest/area/" + this.currentID;

      axios
        .put(urlRequest, this.currentArea, {
          headers: { "Accept-Language": "*" },
        })
        .then((resp) => {
          console.log(resp);
          this.successModal = true;
          this.reset();
        })
        .catch((err) => {
          this.errorMessage = err;
          this.errorModal = true;
        });
    },
    /** 戻るボタン押下 */
    onBackClicked() {
      this.backPage();
    },

    backPage() {
      this.$router.go(-1);
    },
    /** reset data */
    reset() {
      this.AddTagsJa = {};
      this.AddTagsEn = {};
      this.AddTagsZh = {};
      this.currentArea = [];
      this.enTabp = "";
      this.jaTabp = "";
      this.zhTabp = "";
    },
  },
  beforeMount() {
  },
};
</script>